import firebase from './firebase'
const db = firebase.firestore()
var CryptoJS = require("crypto-js");

class DB {

    constructor(collection) {
        this.collection = collection

    }
    reformat(doc) {
        return { id: doc.id, ...doc.data() }
    }

    findByField = async (field, value) => {
        const data = await db.collection(this.collection).where(field, '==', value).get()
        return data.docs.map(this.reformat)
    }

    create = async item => {
        const { id, ...rest } = item
        await db.collection(this.collection).add(rest)
    }
    createDocument = async (item, name) => {
        const { id, ...rest } = item
        await db.collection(name).add(rest)
    }

    remove = async id => {
        await db.collection(this.collection).doc(id).delete()
    }
    removeDocument = async (id, name) => {
        await db.collection(name).doc(id).delete()
    }
    removeAdmin = async id => {
        await db.collection(this.collection).doc(id).delete()
    }

    update = async item => {
        const { id, ...rest } = item
        await db.collection(this.collection).doc(id).set(rest)
    }
    updateDocument = async (item, name) => {
        const { id, ...rest } = item
        await db.collection(name).doc(id).set(rest)
    }

    listenOne = (set, id) =>
        db.collection(this.collection).doc(id).onSnapshot(snap => set(this.reformat(snap)))


    findByUser = async (userid) => {
        const data = await db.collection(this.collection).where("userid", "==", userid).get()
        return data.docs.map(this.reformat)
    }
    findByName = async (name) => {
        const data = await db.collection(this.collection).where("name", "==", name).get()
        return data.docs.map(this.reformat)
    }
    findByUserReply = async (userid) => {
        const data = await db.collection(this.collection).where("cui", "==", userid).get()
        return data.docs.map(this.reformat)
    }
    findByEmail = async (email) => {
        const data = await db.collection(this.collection).where("email", "==", email).get()
        return data.docs.map(this.reformat)
    }

    listenAll = set => {
        db.collection(this.collection).onSnapshot(snap => set(snap.docs.map(this.reformat)))

    }
    finduserid = async (userid, qid, name) => {
        const data = await db.collection(name).where("userid", "==", userid).where("qid", "==", qid).limit(1).get()
        return data.docs.map(this.reformat)
    }
    listenAllDB = (set, name, mov) => {
        db.collection(name).where("movieId", "==", mov)
            .get()
            .then((querySnapshot) => {
                const movs = querySnapshot.docs.map(doc => (
                    {
                        qs: CryptoJS.AES.decrypt(doc.data().question, process.env.REACT_APP_ANSWER).toString(CryptoJS.enc.Utf8),
                        ans: CryptoJS.AES.decrypt(doc.data().answer, process.env.REACT_APP_ANSWER).toString(CryptoJS.enc.Utf8),
                        ...doc.data(),
                        id: doc.id,
                    }))
                set(movs)
            })
            .catch((error) => {
                console.log("Error getting documents: ", error);
            });


    }


    listenAllDBGR = (set, name, mov) => {
        db.collection(name).where("movieId", "==", mov)
            .get()
            .then((querySnapshot) => {
                const movs = querySnapshot.docs.map(doc => (
                    {
                        ans: CryptoJS.AES.decrypt(doc.data().answer, process.env.REACT_APP_ANSWER).toString(CryptoJS.enc.Utf8),
                        ...doc.data(),
                        id: doc.id,
                    }))
                set(movs)
            })
            .catch((error) => {
                console.log("Error getting documents: ", error);
            });


    }
    listenUsers = async (set, userid, mov) => {
        db.collection(this.collection)
            .where("userid", "==", userid)
            .where("movieId", "==", mov)
            .onSnapshot(snap => set(snap.docs.map(this.reformat)))
    }
    listenComments = async (set, userid) => {
        db.collection(this.collection)
            .where("cui", "==", userid)
            .onSnapshot(snap => set(snap.docs.map(this.reformat)))
    }
    listenUsersDocuments = async (set, userid, mov, name) => {
        db.collection(name)
            .where("userid", "==", userid)
            .where("movieId", "==", mov)
            .onSnapshot(snap => set(snap.docs.map(this.reformat)))
    }
    listennumUsersansDocuments = async (set, name) => {
        db.collection(name).onSnapshot(snap => set(snap.docs.map(this.reformat)))
    }

    listenQuestionId = async (set, qid) => {
        db.collection(this.collection).where("qid", "==", qid).onSnapshot(snap => set(snap.docs.map(this.reformat)))
    }
    findAll = async () => {
        const data = await db.collection(this.collection).get()
        return data.docs.map(this.reformat)

    }
    findOne = async (id) => {
        const doc = await db.collection(this.collection).doc(id).get()
        return doc.exists ? this.reformat(doc) : undefined
    }
    findOneQuestion = async (id, name) => {
        const doc = await db.collection(name).doc(id).get()
        return doc.exists ? this.reformat(doc) : undefined
    }
    findstringnum = async (stringnum, name) => {
        const data = await db.collection(name).where("stringnum", "==", stringnum).get()
        return data.docs.map(this.reformat)
    }
}

class Users extends DB {
    constructor() {
        super("users")
    }

}
class Movies extends DB {

    constructor() {
        super("movies")
    }

    reformatOne(item) {

        item = super.reformatOne(item)
        item = { ...item, id: item.id }
        return item
    }

}
class YellowQuestions extends DB {

    constructor() {
        super("yellowQuestions")
    }

    reformatOne(item) {
        item = super.reformatOne(item)
        item = { ...item, id: item.id }
        return item
    }
    findstringnum = async (stringnum) => {
        const data = await db.collection(this.collection).where("stringnum", "==", stringnum).get()
        return data.docs.map(this.reformat)
    }
}
class YellowProQuestions extends DB {

    constructor() {
        super("yellowProQuestions")
    }

    reformatOne(item) {
        item = super.reformatOne(item)
        item = { ...item, id: item.id }
        return item
    }
    findstringnum = async (stringnum) => {
        const data = await db.collection(this.collection).where("stringnum", "==", stringnum).get()
        return data.docs.map(this.reformat)
    }
}
class BlueQuestions extends DB {

    constructor() {
        super("blueQuestions")
    }

    reformatOne(item) {

        item = super.reformatOne(item)
        item = { ...item, id: item.id }
        return item
    }
    findstringnum = async (stringnum) => {
        const data = await db.collection(this.collection).where("stringnum", "==", stringnum).get()
        return data.docs.map(this.reformat)
    }
}
class BlueProQuestions extends DB {

    constructor() {
        super("blueProQuestions")
    }

    reformatOne(item) {
        item = super.reformatOne(item)
        item = { ...item, id: item.id }
        return item
    }
    findstringnum = async (stringnum) => {
        const data = await db.collection(this.collection).where("stringnum", "==", stringnum).get()
        return data.docs.map(this.reformat)
    }
}
class RedQuestions extends DB {

    constructor() {
        super("redQuestions")
    }

    reformatOne(item) {
        item = super.reformatOne(item)
        item = { ...item, id: item.id }
        return item
    }
    findstringnum = async (stringnum) => {
        const data = await db.collection(this.collection).where("stringnum", "==", stringnum).get()
        return data.docs.map(this.reformat)
    }
}
class RedProQuestions extends DB {

    constructor() {
        super("redProQuestions")
    }

    reformatOne(item) {
        item = super.reformatOne(item)
        item = { ...item, id: item.id }
        return item
    }
    findstringnum = async (stringnum) => {
        const data = await db.collection(this.collection).where("stringnum", "==", stringnum).get()
        return data.docs.map(this.reformat)
    }
}
class GreenQuestions extends DB {

    constructor() {
        super("greenQuestions")
    }

    reformatOne(item) {
        item = super.reformatOne(item)
        item = { ...item, id: item.id }
        return item
    }
    findstringnum = async (stringnum) => {
        const data = await db.collection(this.collection).where("stringnum", "==", stringnum).get()
        return data.docs.map(this.reformat)
    }
}

class GreenProQuestions extends DB {

    constructor() {
        super("greenProQuestions")
    }

    reformatOne(item) {
        item = super.reformatOne(item)
        item = { ...item, id: item.id }
        return item
    }
    findstringnum = async (stringnum) => {
        const data = await db.collection(this.collection).where("stringnum", "==", stringnum).get()
        return data.docs.map(this.reformat)
    }
}
class YellowUserAnswers extends DB {

    constructor() {
        super("yellowUserAnswers")
    }

    reformatOne(item) {

        item = super.reformatOne(item)
        item = { ...item, id: item.id }
        return item
    }



}
class YellowProUserAnswers extends DB {

    constructor() {
        super("yellowProUserAnswers")
    }

    reformatOne(item) {

        item = super.reformatOne(item)
        item = { ...item, id: item.id }
        return item
    }





}
class BlueUserAnswers extends DB {

    constructor() {
        super("blueUserAnswers")
    }

    reformatOne(item) {

        item = super.reformatOne(item)
        item = { ...item, id: item.id }
        return item
    }



}
class BlueProUserAnswers extends DB {

    constructor() {
        super("blueProUserAnswers")
    }

    reformatOne(item) {

        item = super.reformatOne(item)
        item = { ...item, id: item.id }
        return item
    }



}
class RedUserAnswers extends DB {

    constructor() {
        super("redUserAnswers")
    }

    reformatOne(item) {

        item = super.reformatOne(item)
        item = { ...item, id: item.id }
        return item
    }



}
class RedProUserAnswers extends DB {

    constructor() {
        super("redProUserAnswers")
    }

    reformatOne(item) {

        item = super.reformatOne(item)
        item = { ...item, id: item.id }
        return item
    }



}
class GreenUserAnswers extends DB {

    constructor() {
        super("greenUserAnswers")
    }

    reformatOne(item) {

        item = super.reformatOne(item)
        item = { ...item, id: item.id }
        return item
    }


}
class GreenProUserAnswers extends DB {

    constructor() {
        super("greenProUserAnswers")
    }

    reformatOne(item) {

        item = super.reformatOne(item)
        item = { ...item, id: item.id }
        return item
    }



}
class Comments extends DB {

    constructor() {
        super("comments")
    }

    reformatOne(item) {

        item = super.reformatOne(item)
        item = { ...item, id: item.id }
        return item
    }


}
class CommentReplys extends DB {

    constructor() {
        super("commentreplys")
    }

    reformatOne(item) {

        item = super.reformatOne(item)
        item = { ...item, id: item.id }
        return item
    }


}

class Quotes extends DB {

    constructor() {
        super("quotes")
    }

    reformatOne(item) {

        item = super.reformatOne(item)
        item = { ...item, id: item.id }
        return item
    }


}
class QuotesLikes extends DB {

    constructor() {
        super("quotesLikes")
    }

    reformatOne(item) {

        item = super.reformatOne(item)
        item = { ...item, id: item.id }
        return item
    }
    findquoteanduserid = async (userid, quoteid) => {
        const data = await db.collection(this.collection).where("userid", "==", userid).where("quoteid", "==", quoteid).get()
        return data.docs.map(this.reformat)
    }
    finduseridlikes = async (set, userid) => {
        db.collection(this.collection)
            .where("userid", "==", userid)
            .onSnapshot(snap => set(snap.docs.map(this.reformat)))
    }
    findnumoflikes = async (set, quoteid) => {
        db.collection(this.collection)
            .where("quoteid", "==", quoteid)
            .onSnapshot(snap => set(snap.docs.map(doc => (
                { id: doc.id, ...doc.data() })
            )))
    }

}
class MoviesLikes extends DB {

    constructor() {
        super("moviesLikes")
    }

    reformatOne(item) {

        item = super.reformatOne(item)
        item = { ...item, id: item.id }
        return item
    }
    findmovieanduserid = async (userid, movieid) => {
        const data = await db.collection(this.collection).where("userid", "==", userid).where("movieid", "==", movieid).get()
        return data.docs.map(this.reformat)
    }
    finduseridlikes = async (set, userid) => {
        db.collection(this.collection)
            .where("userid", "==", userid)
            .onSnapshot(snap => set(snap.docs.map(this.reformat)))
    }


}

class SubscribedUsers extends DB {

    constructor() {
        super("subscribedUsers")
    }

    reformatOne(item) {

        item = super.reformatOne(item)
        item = { ...item, id: item.id }
        return item
    }

    findByUserAndType = async (userid, type) => {
        const data = await db.collection(this.collection).where("userid", "==", userid).where("type", "==", type).get()
        let r = data.docs.map(this.reformat)
        return data.docs.map(this.reformat)
    }
    findByUserSubs = async (userid) => {
        const data = await db.collection(this.collection).where("userid", "==", userid).get()
        return data.docs.map(this.reformat)
    }
    findByDate = async (userid, date) => {
        const data = await db.collection(this.collection).where("userid", "==", userid).where("end", ">=", date).get()
        let r = data.docs.map(this.reformat)
        return data.docs.map(this.reformat)
    }
}


class Characters extends DB {

    constructor() {
        super("characters")
    }

    reformatOne(item) {

        item = super.reformatOne(item)
        item = { ...item, id: item.id }
        return item
    }

}
class CharactersPro extends DB {

    constructor() {
        super("charactersPro")
    }

    reformatOne(item) {

        item = super.reformatOne(item)
        item = { ...item, id: item.id }
        return item
    }

}

class NumofQuestions extends DB {
    constructor() {
        super("numofquestions")
    }
    reformatOne(item) {
        item = super.reformatOne(item)
        item = { ...item, id: item.id }
        return item
    }

}
class Notifications extends DB {
    constructor() {
        super("notifications")
    }
    reformatOne(item) {
        item = super.reformatOne(item)
        item = { ...item, id: item.id }
        return item
    }

}
const classes = {
    Users: new Users(),
    Movies: new Movies(),
    YellowQuestions: new YellowQuestions(),
    YellowProQuestions: new YellowProQuestions(),
    BlueQuestions: new BlueQuestions(),
    BlueProQuestions: new BlueProQuestions(),
    RedQuestions: new RedQuestions(),
    RedProQuestions: new RedProQuestions(),
    GreenQuestions: new GreenQuestions(),
    GreenProQuestions: new GreenProQuestions(),
    YellowUserAnswers: new YellowUserAnswers(),
    YellowProUserAnswers: new YellowProUserAnswers(),
    BlueUserAnswers: new BlueUserAnswers(),
    BlueProUserAnswers: new BlueProUserAnswers(),
    RedUserAnswers: new RedUserAnswers(),
    RedProUserAnswers: new RedProUserAnswers(),
    GreenUserAnswers: new GreenUserAnswers(),
    GreenProUserAnswers: new GreenProUserAnswers(),
    Comments: new Comments(),
    CommentReplys: new CommentReplys(),
    Quotes: new Quotes(),
    QuotesLikes: new QuotesLikes(),
    MoviesLikes: new MoviesLikes(),
    SubscribedUsers: new SubscribedUsers(),
    Characters: new Characters(),
    CharactersPro: new CharactersPro(),
    NumofQuestions: new NumofQuestions(),
    Notifications: new Notifications()

};
export default classes;
