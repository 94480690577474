
import db from "./db";
import firebase from "./firebase";
import React, { useState, useEffect, lazy, Suspense  } from "react";
import UserContext from "./UserContext";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import $ from "jquery";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import "./starter/Home/header.css";
import "./starter/Home/enginfo.css";
import "./starter/Home/footer.css";
import "./starter/Home/subscription.css";
import "./starter/Home/moviestypes.css";
import "./starter/Home/Notifications.css";
import "./starter/YellowAll/yellow.css";
import "./starter/BlueAll/blue.css";
import "./starter/AdminAll/adminMovies.css";
import "./starter/MoviesTypes/AllMoviesTypes.css";
import "./starter/MoviesTypes/AllTypes.css";
import "./starter/RedAll/red.css";
import "./starter/GreenAll/green.css";
import "./components/Paypal.css";
import "./starter/MoviesAll/moviesdetails.css";
import "./starter/MoviesAll/search.css";
import "./starter/MoviesAll/questionstabs.css";
import "./starter/MoviesAll/comments.css";
import "./starter/Quotes/quotes.css";
import "./starter/Quotes/Allquotes.css";
import "./css/gsapstyle.css";
import "./css/Login.css";
import "./css/navbar.css";
import "./css/profile.css";
import "./starter/user/favmovies.css";
const Home = lazy(() => import('./starter/Home/Home'))
const Login = lazy(() => import('./Login'))
const MoviesDetails = lazy(() => import('./starter/MoviesAll/MoviesDetails'))
const Profile = lazy(() => import('./starter/user/Profile'))
const Logout = lazy(() => import('./Logout'))
const AllMoviesTypes = lazy(() => import('./starter/MoviesTypes/AllMoviesTypes'))
const AllTypes = lazy(() => import('./starter/MoviesTypes/AllTypes'))
const AllQuotes = lazy(() => import('./starter/Quotes/AllQuotes'))
const SearchMovie = lazy(() => import('./starter/MoviesAll/SearchMovie'))
const PayPal = lazy(() => import('./components/PayPal'))
const Notifications = lazy(() => import('./starter/Home/Notifications'))
const AdminMovies = lazy(() => import('./starter/AdminAll/AdminMovies'))
const FavMovies = lazy(() => import('./starter/user/FavMovies'))

export default function App() {
  const renderLoader = () =>
    <h1 style={{ color: "white" }}>Just wait a second</h1>
  const [user, setUser] = useState(null);

  useEffect(() => {
    const findAndSetUser = async (user) => {

      let dbUser = null;
      if (user) {
        dbUser = await db.Users.findOne(user.uid);
        if (!dbUser) {
          dbUser = await db.Users.findOne(user.uid);
        }
      }
      setUser(dbUser);
    };
    return firebase.auth().onAuthStateChanged(findAndSetUser);
  }, []);

  const isPublic = () => user === null;
  const isLoggedIn = () => user !== null;
  const isAdmin = () => user?.id === process.env.REACT_APP_ADMIN;
  const isCustomer = () => user?.role === "Customer";


  useEffect(() => {

    (function () {
      $(document).on('click', '.search-button', function () {
        $(this).parent().parent().toggleClass('active');
      });
      $(document).on('click', '.movie-list', function () {
        $(this).parent().parent().toggleClass('active');
      });

    })();
    const app = (() => {
      let body;
      let menu;
      let menuItems;
      let nav;
      let search;
      let cont;
      const init = () => {
        body = document.querySelector('.body');
        menu = document.querySelector('.menu-icon');
        menuItems = document.querySelectorAll('.nav__list-item');
        nav = document.querySelector('.nav__content');
        search = document.querySelector('.search-holder')
        applyListeners();
      }

      const applyListeners = () => {
        menu.addEventListener('click', () => toggleClass(body, 'nav-active'));
      }

      const toggleClass = (element, stringClass) => {
        if (element.classList.contains(stringClass)) {
          search.style.visibility = "hidden"
          nav.style.visibility = "hidden"

          element.classList.remove(stringClass);
        }
        else {
          element.classList.add(stringClass);
          search.style.visibility = "visible"

          nav.style.visibility = "visible"


        }
      }

      init();
    })();


  }, [])

  return (
    <PayPalScriptProvider
      options={{ "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID }}
    >
      <UserContext.Provider value={{user}}>
        <Suspense fallback={renderLoader()}>
          <Router>

            <div className="">
              <div className="logopng" >
                <a href="/">
                  <img className="logopng" src={process.env.PUBLIC_URL + '/decore/logo.png'}></img>
                </a>
         

              </div>

              <img className="menu-wrapper" src={process.env.PUBLIC_URL + '/decore/menu.png'}></img>
              <div className="body" >
                <div className="menu-icon">
                  <span className="menu-icon__line menu-icon__line-left"></span>
                  <span className="menu-icon__line"></span>
                  <span className="menu-icon__line menu-icon__line-right"></span>
                </div>
                <div className="search-holder">
                  <div className="circley"></div>
                  <div className="formg">
                    <SearchMovie />

                  </div>
                </div>
                <div className="nav">
                  <div className="nav__content">

                    <ul className="nav__list">

                      <a href="/">   <li className="nav__list-item">الصفحة الرئيسية</li></a>

                      <a onClick={() => { window.location.href = `/genrelist` }}>  <li className="nav__list-item">الأفلام</li></a>
                      <a href="/allQuotes"> <li className="nav__list-item">حكم وأقوال</li></a>
                      {isPublic() && (
                        <>
                          <a href="/login">  <li className="nav__list-item">تسجيل الدخول</li></a>
                        </>
                      )}
                      {isLoggedIn() && (
                        <>
                          <a href="/favmovies">  <li className="nav__list-item">المفضلة</li></a>
                          <a href="/profile">  <li className="nav__list-item">الملف الشخصي</li></a>
                          {user?.id === process.env.REACT_APP_ADMIN && <a href="/adminmovies">  <li className="nav__list-item">Admin Control</li></a>}
                          <a href="/logout">  <li className="nav__list-item">تسجيل الخروج</li></a>
                        </>
                      )}

                    </ul>
                  </div>
                </div>

              </div>






              {isPublic() && (
                <Switch>
                  <Route path="/login" >
                    <Login page={"/"} />
                  </Route>
                  <Route path="/searchMovie">
                    <SearchMovie />
                  </Route>
                  <Route path="/moviesdetails/:id" set={setUser}>
                    <MoviesDetails />
                  </Route>
                  <Route path="/genrelist" set={setUser}>
                    <AllTypes />
                  </Route>

                  <Route path="/payment" set={setUser}>
                    <PayPal />
                  </Route>
                  <Route path="/allQuotes" set={setUser}>
                    <AllQuotes />
                  </Route>
                  <Route path="/">
                    <Home />
                  </Route>

                </Switch>
              )}
              {isLoggedIn() && (
                <Switch>
                  <Route path="/logout">
                    <Logout />
                  </Route>
                </Switch>
              )}
              {isCustomer() && (
                <Switch>
                  <Route path="/searchMovie">
                    <SearchMovie />
                  </Route>
                  <Route path="/genrelist" set={setUser}>
                    <AllTypes />
                  </Route>
                  <Route path="/notifications">
                    <Notifications />
                  </Route>

                  <Route path="/payment" set={setUser}>
                    <PayPal />
                  </Route>
                  <Route path="/moviesdetails/:id" set={setUser}>
                    <MoviesDetails />
                  </Route>
                  <Route path="/allQuotes" set={setUser}>
                    <AllQuotes />
                  </Route>
                  <Route path="/profile">
                    <Profile set={setUser} />
                  </Route>

                  <Route path="/favmovies">
                    <FavMovies set={setUser} />
                  </Route>

                  <Route path="/">
                    <Home />
                  </Route>
                </Switch>
              )}
              {isAdmin() && (
                <Switch>
                  <Route path="/adminmovies">
                    <AdminMovies />
                  </Route>
                  <Route path="/genrelist" set={setUser}>
                    <AllTypes />
                  </Route>
                  <Route path="/payment" set={setUser}>
                    <PayPal />
                  </Route>
                  <Route path="/notifications">
                    <Notifications />
                  </Route>
                  <Route path="/profile">
                    <Profile set={setUser} />
                  </Route>
                  <Route path="/allQuotes" set={setUser}>
                    <AllQuotes />
                  </Route>
                  <Route path="/favmovies">
                    <FavMovies set={setUser} />
                  </Route>
                      <Route path="/moviesdetails/:id">
                    <MoviesDetails />
                  </Route>
                  <Route path="/">
                    <Home />
                  </Route>
                </Switch>
              )}
            </div>
          </Router>
        </Suspense>
      </UserContext.Provider>
    </PayPalScriptProvider>
  );
}
